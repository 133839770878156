.container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 400px;
  margin: auto;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
}

.title {
  width: 300px;
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  margin: 0 20px 0 20px;
}

.ad-container {
  position: fixed;
  bottom: 0px;
}