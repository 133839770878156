.MuiPaper-root {
  width: 400px;
  border-radius: 0 !important;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-image {
  margin-bottom: 10px;
  max-width: 280px;
  min-width: 220px;
}

.answer {
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 100;
}

.guess-attempts {
  text-align: center;
  margin-bottom: 10px;
}

.timer-text {
  size: 18px;
}

.timer {
  font-family: monospace;
  font-size: 40px;
  font-weight: 900;
}