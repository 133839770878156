.title {
  font-size: 20px !important;
  font-weight: 100 !important;
}

.date {
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 10px;
}

.image {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  min-width: 220px;
  min-height: 220px;
  margin-bottom: 20px;
}

.hint {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 5px 0 5px;
  color: white;
}

.guesses-remaining {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 100;
  height: 18px;
}

.dots {
  width: 100px;
  position: relative;
  margin-left: 5px;
  top: 2px;
}

.guess {
  display: flex;
  width: 380px;
  position: relative;
}

.guess-item {
  flex: 0 0 auto;
}

.guesses {
  width: 380px;
  text-align: center;
  margin-bottom: 80px;
}